<template>
  <div>
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="title">
          <div class="image" @click="shouye">
            <el-carousel height="80px" direction="vertical" arrow="never" indicator-position="none" :interval="5000">
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="titleBtn">
            <div @click="shouye">首页</div>
            <div @click="shanpin">产品中心</div>
            <div @click="zixun">资讯动态</div>
            <div class="active" @click="zhaoxian">招贤纳士</div>
            <div @click="yuedong">走进跃动</div>
            <div @click="lianxi">联系我们</div>
          </div>
        </div>
      </el-header>
      <!-- 主体 -->
      <el-main>
        <div class="careersmain">
          <div>
            加入我们<br />
            梦想从这里起航
          </div>
        </div>
        <div class="careersinfo">
          <!-- 折叠面板 -->
          <div class="card">招贤纳士</div>
          <div class="cool">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item :name="i+1" v-for="(item,i) of data" :key="i">
                <span class="collapse-title" slot="title">
                  <div class="totl">
                    <div>{{item.position}}</div>
                    <div class="totlinfo">
                      <div>薪资面议</div>
                      <div class="shuxian"></div>
                      <div>大专以上</div>
                      <div class="shuxian"></div>
                      <div>招聘 2 人</div>
                    </div>
                  </div>
                  <img v-if="activeName != i+1" src="../assets/img/bottom.png" />
                  <img v-else src="../assets/img/top.png" />
                </span>
                <div class="content">
                  <div class="gangwei1">任职资格：</div>
                  <div v-html="item.qualification"></div>
                  <div class="gangwei2">岗位职责：</div>
                  <div v-html="item.responsibility"></div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <!-- 联系方式 -->
          <div class="contact">
            <div class="join">您可通过以下方式加入我们：</div>
            <div class="phone">
              <div class="dianhua">
                <img src="../assets/img/phone.png" />
                <div class="yixiang">
                  <div>意向电话</div>
                  <div>023-68636622</div>
                </div>
              </div>
              <div class="youxiang">
                <img src="../assets/img/email.png" />
                <div class="yixiang">
                  <div>投递邮箱</div>
                  <div class="eml">yedone@yedone.com</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <footerComponents></footerComponents>
    </el-container>
  </div>
</template>
<script>
import footerComponents from "../components/footer.vue";
export default {
  name: "Careers",
  components: { footerComponents },
  data() {
    return {
      activeName: "0",
      data:[]
    };
  },
  mounted() {
	  if(/Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)) {
	  	//移动设备打开的网页，缩放页面尺寸
	  	/*
	  	var clientWidth = document.body.clientWidth;
	  	var rate = clientWidth / 1920;
	  	*/
	     var rate = 0.1;
	  	var meta = document.getElementsByTagName('meta');
	  	meta['viewport'].setAttribute('content', 'width=device-width,initial-scale='+rate);
	  }
     this.$http.get('/api/job').then(res=>{
      let a= JSON.parse(res.data)
      if(a[0].code=='success'){
        this.data=a[0].data
        console.log(a[0].data)
      }
    })
  },
  methods: {
    //跳转首页
    shouye() {
      this.$router.push("/");
    },
    //跳转产品中心
    shanpin() {
      this.$router.push("/product");
    },
    //跳转资讯动态
    zixun() {
      this.$router.push("/information");
    },
    //跳转招贤纳士
    zhaoxian() {
      this.$router.push("/careers");
    },
    //跳转走进跃动
    yuedong(){
      this.$router.push("/yedone");
    },
    //联系我们
    lianxi(){
      this.$router.push("/contactUs");
    }
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1480px;
  padding: 0;
  margin: 0 auto;
  height: 80px !important;
  line-height: 80px;
  .title {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    .image {
      min-width: 158px;
      height: 80px;
      margin-right: 52px;
      img {
        width: 158px;
        height: 40px;
        padding-top: 20px;
      }
      &:hover{
        cursor: pointer;
      }
    }
    .titleBtn {
      display: flex;
      align-items: center;
      height: 80px;
      box-sizing: border-box;
      > div {
        margin-left: 68px;
        width: 72px;
        height: 27px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 27px;
        color: #666666;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
      .active {
        position: relative;
        color: #004097;
      }
      .active::after {
        position: absolute;
        bottom: -10px;
        content: "";
        width: 30px;
        height: 4px;
        background: #004097;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1480px;
  margin: 0 auto;
  padding: 0;
  .careersmain {
    height: 620px;
    background-image: url(../assets/img/careersback.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    padding-top: 220px;
    > div:nth-child(1) {
      width: 1200px;
      height: 139px;
      font-size: 48px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 68px;
      color: #ffffff;
      letter-spacing: 4px;
      margin: 0 auto;
    }
  }
  .careersinfo {
    background: #f4f5f9;
    padding-top: 60px;
    .card {
      width: 1200px;
      margin: 0 auto;
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 36px;
      color: #161e2a;
    }
    .cool {
      width: 1200px;
      margin: 0 auto;
      .el-collapse {
        box-sizing: border-box;
        .el-collapse-item {
          background: #ffffff;
          border-radius: 12px;
          margin-top: 30px;
          padding: 60px;
          // 更改标题样式
          /deep/ .el-collapse-item__header {
            border: none;
            height: 91px;
            .collapse-title {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .totl {
                line-height: normal;
                > div:nth-child(1) {
                  font-size: 32px;
                  font-family: Source Han Sans SC;
                  font-weight: 500;
                  line-height: 36px;
                  color: #161e2a;
                }
                .totlinfo{
                  font-size: 16px;
                  font-family: Source Han Sans SC;
                  font-weight: 400;
                  line-height: 28px;
                  color: #8c97a8;
                  padding-top: 20px;
                  display: flex;
                  align-items: center;
                  .shuxian{
                    width: 0px;
                    height: 14px;
                    border: 1px solid #CFD3D9;
                    margin: 0 10px;
                  }
                }
              }
            }
          }
          // 更改下拉文字样式
          /deep/ .el-collapse-item__wrap {
            border: none;
            background: #f4f5f9;
            border-radius: 8px;
            margin-top: 27px;
            .el-collapse-item__content {
              padding: 40px;
              .content {
                font-size: 16px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                line-height: 28px;
                color: #626778;
                .gangwei1,
                .gangwei2 {
                  font-size: 18px;
                  font-weight: bold;
                  color: #161e2a;
                }
                .gangwei1 {
                  margin-bottom: 15px;
                }
                .gangwei2 {
                  margin: 30px 0 15px;
                }
              }
            }
          }
        }
      }

      //清除箭头图标
      /deep/ .el-icon-arrow-right {
        display: none;
      }
    }
    .contact {
      width: 1200px;
      padding: 60px 0;
      margin: 0 auto;
      .join {
        font-size: 24px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #161e2a;
      }
      .phone {
        display: flex;
        align-items: center;
        margin-top: 30px;
        .dianhua,
        .youxiang {
          display: flex;
          align-items: center;
          .yixiang {
            margin-left: 15px;
            font-size: 20px;
            font-family: DIN;
            font-weight: 400;
            line-height: 18px;
            color: #161e2a;
            div:nth-child(1) {
              font-size: 14px;
              font-family: Source Han Sans SC;
              color: #626778;
              margin-bottom: 4px;
            }
            .eml{
              font-family: Source Han Sans SC;
            }
          }
        }
        .youxiang {
          margin-left: 155px;
        }
      }
    }
  }
}
</style>
